<template>
  <div class="menu-cont show">
    <img
      style="width: 100%"
      src="//cnstatic01.e.vhall.com/static/images/menu/detail-bg-new-h5.png"
      alt=""
    />
  </div>
</template>

<script>
  export default {
    name: 'component-des'
  };
</script>

<style lang="less" scoped>
  .menu-cont {
    width: 286px;
    margin: 0 auto;
    img {
      border-radius: 0 0 13px 13px;
    }
  }
</style>
